import dataLayer from 'assets/core/js/page/homepage/datalayer'
import newsletter from 'assets/themes/campings/js/module/newsletter'
import cms from 'assets/themes/campings/js/page/homepage/cms'
import form from 'assets/themes/campings/js/page/homepage/form'
import alertError from 'assets/themes/campings/js/page/homepage/alert-error'

document.addEventListener('DOMContentLoaded', function () {
  newsletter()
  cms()
  form()
  alertError()
  dataLayer()
})
